import '@mdi/font/css/materialdesignicons.css'
import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            light: {
                secondary: '#d7c194', // #FFCDD2
            },
            dark: {
                secondary: '#d7c194', // #FFCDD2
            },
        },
    },
});
