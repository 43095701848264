<template>
  <v-app>
    <v-main class="main-content">
      <router-view></router-view>
    </v-main>
    <v-container fluid class="footer py-3" v-if="$route.fullPath === '/pravne-informacije'">
      <v-container>
        <p class="footer-text font-weight-light mb-0 w-100">
          © 2021 Copyright © 2020 MASTER F&B Ltd., EUID HRSR.081326069. All rights reserved.

<!--          Powered by <span class="font-weight-bold pointer" v-on:dblclick="loadOffline">v3</span>-->
          <br/>
          info@master-fb.com
        </p>
      </v-container>
    </v-container>
  </v-app>
</template>

<script>
import localforage from "localforage";
export default {
  name: "App",

  methods: {
    loadOffline() {
      let hash = localStorage.vina_hash;
      if (hash != 'undefined')
        this.$router.push('/' + hash + '/true')
    }
  },
};
</script>

<style lang="scss">
* {
  font-family: 'Lato', sans-serif;
}

.closed {
  display: none !important;
}

.list-button {
  position: absolute;
  z-index: 2;
  pointer-events: auto;
  left: 5px;
  top: 5px;
}

.buttonActive {
  background-color: #000 !important;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #212121;
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 8px;
  border-radius: 8px;
  background: #6d6d6d;
}

.v-list-item__title {
  overflow: visible !important;
}

.flickity-viewport {
  min-height: 400px !important;
  &:focus {
    outline: none !important;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-content, body {
  background-color: #000;
}

.main-btn {
  letter-spacing: 0 !important;
  text-transform: capitalize !important;
  border: 1px solid #272c33;
}

.highlighted {
  border: 1px solid #d7c194 !important;
}

.position-relative {
  position: relative !important;
}

.w-100 {
  width: 100% !important;
}

.w-75 {
  width: 75% !important;
}

.w-50 {
  width: 50% !important;
}

@media (min-width: 960px) {
  .w-md-50 {
    width: 50% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
}
.burgundy-text {
  color: #800020;
}

.gold-text {
  color: #d7c194;
}
.footer {
  position: fixed;
  bottom: 0;
}
.header {
  position: fixed;
  z-index: 3;
  top: 0;
}
.footer-text {
  color: rgba(255, 255, 255, .6);
}
.disabled-white {
  color: #FFF;
  opacity: 37% !important;
}
.secondary-white {
  color: #FFF;
  opacity: 60% !important;
}
.primary-white {
  color: #FFF;
  opacity: 87% !important;
}
.flex-column {
  flex-direction: column;
}
.pointer {
  cursor: pointer;
}
.v-dialog {
  position: relative;
  background: #FFFFFF;
  overflow: hidden;
}

// Common
.carousel-div {
  opacity: 0 !important;
  width: 100%;
  z-index: 1;
  transition: .6s ease;
  position: absolute;
  border: 1px solid #272c33;
  bottom: 0;
  background-color: #191c21;
  min-height: 43%;
}

.image-wrap {
  .cell-bottle {
    transition: .6s ease;
    margin-bottom: -10vh;
  }
}

.carousel-wrap {
  height: 100%;
  transition: .6s ease;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.carousel-text {
  z-index: 2;
  opacity: 0 !important;
  align-items: center;
  display: flex;
  flex-direction: column;
  transition: .6s ease;
}

.carousel {
  min-height: 80vh !important;
}

.wines-3 {
  min-height: 100vh !important;
  display: flex;
  justify-content: flex-end;
}

.wines-2,
.wines-4 {
  min-height: 100vh;
  overflow-y: auto;
  padding-top: 20vh !important;
  h2 {
    z-index: 3;
  }
}

.prices {
  font-size: 1.2rem;
}

.slide-cell {
  min-height: 80vh;
  background: transparent !important;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 85vh !important;
}

.image-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.current, .swiper-slide-active {
  .carousel-div, .carousel-text {
    opacity: 1 !important;
    display: flex !important;
  }

  .image-wrap {
    .cell-bottle {
      transition: .5s ease;
      margin-bottom: 0;
    }
  }
}

.filter-menu {
  overflow-y: auto;
  max-height: 60vh;
}

.details-view {
  background-color: #f5f6fa;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  > * {
    z-index: 2;
  }

  .dialogImage {
    max-height: 60vh !important;
  }
}

.justify-content-center {
  justify-content: center !important;
}

.order-dialog {
  .v-dialog {
    width: 60% !important;
  }
}
</style>
