import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/demo",
		redirect: "/OrHppgaNgifNory7b8Wks0fOn/true"
	},
	{
		path: "/pravne-informacije",
		name: "Legal",
		component: () =>
			import("../views/Legal.vue")
	},
	{
		path: "/products/1",
		name: "Wines",
		component: () =>
			import("../views/Wines.vue")
	},
	{
		path: "/products/:productType",
		name: "Products",
		component: () =>
			import("../views/Products.vue")
	},
	{
		path: "/all-products",
		name: "AllProducts",
		component: () =>
			import("../views/AllProducts.vue")
	},
	{
		path: "/cart",
		name: "Cart",
		component: () =>
			import("../views/Cart.vue")
	},
	{
		path: "/:hash/:fromServer?",
		name: "ClientHome",
		component: Home
	},
];

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
}

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
	window.scrollTo(0, 0);
	return next();
});

export default router;
