<template>
  <div class="home">
    <div v-if="client" class="client flex-center flex-column">
      <v-container fluid class="home-1 py-8 px-0"></v-container>
      <div
          v-if="current && showAds"
          class="home-overlay flex-center"
          @click="closeAd()"
          ref="overlay"
      >
        <v-img
            v-if="!current.image_url == false"
            :src="current.image_url"
            :alt="current.title"
            class="big-ad-full"
            contain
        ></v-img>
        <div v-else style="height: auto; width: 100%">
          <v-img v-if="current.video_url.substr(-4) === 'webp'" :src="current.video_url"></v-img>
          <div v-else>
            <video v-if="length_ads > 1" @ended="endVideo()" id="myVideo" autoplay muted height="100%" width="100%"
                   :src="current.video_url"></video>
            <video v-else autoplay muted height="100%" width="100%" :src="current.video_url" loop></video>
          </div>
        </div>
        <!--        <v-container class="flex-center flex-column">
                  <v-row class="ma-0 flex-center w-100">
                    <v-col>
                      <h4 class="w-100 text-center white&#45;&#45;text text-h4">
                        {{ $t("adInst") }}
                      </h4>
                    </v-col>
                    <v-col cols="12" class="flex-center flex-column">
                      <v-img
                        :src="current.image_url"
                        :alt="current.title"
                        class="big-ad"
                        contain
                      ></v-img>
                    </v-col>
                    <v-col cols="12" class="flex-center flex-column">
                      <h1 class="font-weight-bold w-100 text-center white&#45;&#45;text">
                        {{ current.title }}
                      </h1>
                      <p class="white&#45;&#45;text w-100 text-center">
                        {{ current.description }}
                      </p>
                    </v-col>
                  </v-row>
                </v-container>-->
      </div>

      <template v-else>
        <div class="header w-100 flex-center py-8">
          <v-container class="d-flex justify-space-between align-items-center">
            <v-menu
                transition="slide-y-transition"
                bottom
                offset-y
                min-width="300"
                persistent
            >
              <template v-slot:activator="{ on }">
                <v-btn
                    v-if="lang"
                    color="#272c33"
                    depressed
                    height="50"
                    dark
                    class="main-btn pl-1 mb-4"
                    v-on="on"
                >
                  <country-flag
                      :country="lang.flag"
                      size="normal"
                      class="mx-1"
                  />
                  {{ lang.title }}
                </v-btn>
              </template>
              <v-list dark class="py-3 language text-left" v-if="supportedLangs" style="width: 250px">
                <v-list-item
                    v-for="(language, index) in supportedLangs"
                    :key="index"

                >

                  <div style="border: 1px solid rgba(255,255,255,0.24); border-radius: 5px; height: 50px"
                       class=" mb-2 w-100 pl-2" @click="setLang(language.abbreviation)">
                    <v-row class="pa-0 ma-0" align="center">
                      <country-flag
                          :country="language.flag"
                          size="normal"
                          class="mx-1 mr-4  mt-1"
                      />
                      <span class="mt-3">{{ language.title }}</span>
                    </v-row>
                  </div>

                  <!--                  <v-btn-->
                  <!--                      depressed-->
                  <!--                      text-->
                  <!--                      height="50"-->
                  <!--                      class="main-btn pl-1 mb-2 w-100"-->
                  <!--                      @click="setLang(language.abbreviation)"-->
                  <!--                  >-->
                  <!--                    <country-flag-->
                  <!--                        :country="language.flag"-->
                  <!--                        size="normal"-->
                  <!--                        class="mx-1 mr-4"-->
                  <!--                    />-->
                  <!--                    {{ language.title }}-->
                  <!--                  </v-btn>-->
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
                color="#d7c194"
                depressed
                height="50"
                class="main-btn px-5 mr-5"
                @click="goTo('/pravne-informacije')"
            >
              <v-icon class="mr-3">mdi-shield-search</v-icon>
              {{ $t("legal") }}
            </v-btn>
          </v-container>
        </div>
        <v-container class="w-100 home-2 flex-center flex-column">
          <h2 class="w-100 text-center gold-text font-weight-light mb-10">
            {{ $t("menu") }}
          </h2>
          <v-row class="w-100 mx-0 mt-5 flex-center">
            <v-col
                v-for="type in productTypes"
                :key="type.title"
                :cols="productTypes.length % 2 === 0 ? 6 : 4"
                class="flex-center px-5"
            >
              <div class="menu-item" @click="goTo('/products/' + type.id)">
                <!--                <v-icon size="50" color="rgba(255, 255, 255, .4)">{{ type.icon }}</v-icon>-->
                <v-img :lazy-src="type.image_url" :src="type.image_url" max-height="26vh" contain></v-img>
                <p class="w-100 text-center mb-0 mt-3">{{ type.title }}</p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </div>
  </div>
</template>

<script>

import localforage from "localforage";

export default {
  name: "Home",
  data() {
    return {
      counter: 0,
      current: null,
      setAdTimeout: null,
      menuTimeout: null,
      length_ads: 0,
    };
  },
  computed: {
    lang() {
      return this.$store.getters.getLang;
    },
    grouped_products() {
      return this.$store.getters.getGroupedProducts;
    },
    supportedLangs() {
      return this.$store.getters.getsupportedLangs;
    },
    ads() {
      return this.$store.getters.getHomeAds;
    },
    client() {
      return this.$store.getters.getClient;
    },
    showAds() {
      return this.$store.getters.showAds;
    },
    productTypes() {
      let products = [];
      this.$store?.getters?.getProductTypes?.forEach((element) => {
        if (this.grouped_products[element.id]) {
          products.push(element);
        }
      });
      return products;
    },
  },
  methods: {
    goTo(value) {
      this.$router.push(value);
    },
    setCurrent() {
      let time = 4000
      this.length_ads = this.ads?.length
      if (this.ads?.length > 0) {
        let ad = this.ads[this.counter];
        this.current = ad;
        this.incrementCounter();
        if (!this.current.video_url == false) {
          // var vid = document.getElementById("myVideo");
          return
        } else {
          time = 4000
        }
      }
      this.setAdTimeout = setTimeout(() => {
        this.setCurrent();
      }, time);
    },
    endVideo() {
      this.setCurrent()
    },
    incrementCounter() {
      if (this.counter === this.ads.length - 1) {
        this.counter = 0;
      } else {
        this.counter++;
      }
    },
    async setLang(lang) {
      await this.$store.dispatch("setLang", lang);
      this.$i18n.locale = lang;
    },
    closeAd() {
      this.$store.dispatch("showAds", false);
      this.startTimeout();
    },
    startTimeout() {
      this.menuTimeout = setTimeout(() => {
        clearTimeout(this.setAdTimeout);
        this.setCurrent();
        // this.$store.commit("clearFavs");
        this.$store.dispatch("showAds", true);
      }, 1*60*1000);
    },
  },
  async created() {
    localStorage.vina_hash = this.$route.params.hash;
    let fromServer = this.$route.params.fromServer == "true";
    if (fromServer) {
      localStorage.removeItem('vinaLang')
      await localforage.clear()
    }
    await this.$store.dispatch("getsupportedLangs", {
      fromServer: fromServer,
      hash: this.$route.params.hash,
    });
    await this.$store.dispatch("getAds", {
      fromServer: fromServer,
      hash: this.$route.params.hash,
    });

    setTimeout(() => {
      this.$store.dispatch("getProductTypes", {
        fromServer: fromServer,
      });
      this.$store.dispatch("getClient", {
        fromServer: fromServer,
        hash: this.$route.params.hash,
      });
      this.$store.dispatch("getFilters", {
        fromServer: fromServer,
      });
      this.$store.dispatch("getRegions", {
        fromServer: fromServer,
      });
      this.$store.dispatch("getCountries", {
        fromServer: fromServer,
      });
      this.$i18n.locale = this.lang?.abbreviation;
      if (localStorage.vinaLang) {
        this.setLang(localStorage.vinaLang)
      }
    }, 500);
    setInterval(() => {
      this.$store.dispatch("syncClientStatistics", {
        fromServer: fromServer,
      });
    }, 1000 * 30);
    if (this.ads?.length > 0) {
      this.startTimeout();
    }
  },
  destroyed() {
    clearTimeout(this.menuTimeout);
  },
  watch: {
    ads: function (newVal, oldVal) {
      if (oldVal?.length === 0 && newVal?.length > 0) {
        this.startTimeout();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.client {
  min-height: 100vh;
  position: relative;
}

.home-overlay {
  position: absolute;
  top: 0;
  min-height: 100vh;
  width: 100vw;
  z-index: 4;
}

.big-ad {
  width: 100% !important;
  max-height: 60vh !important;
  width: auto;
  object-fit: cover;
}

.big-ad-full {
  width: 100% !important;
  height: auto;
  object-fit: cover;
}

.home-1 {
  height: 45vh;
  position: fixed;
  top: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.3)),
  url("../assets/img/vinograd.png");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
}

.home-2 {
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-logo {
  max-height: 200px;
}

.home-title {
  font-size: 50px;
}

.home-col {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.menu-item {
  width: 100%;
  height: 35vh;
  background-color: transparent;
  //border: 1px solid #272c33;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  * {
    transition: 0.3s ease;
  }

  p {
    color: rgba(255, 255, 255, 0.4);
    font-size: 45px;
    text-shadow: -1px 0 #ba8c2f, 0 1px #ba8c2f, 1px 0 #ba8c2f, 0 -1px #ba8c2f;

    color: #d7c194;
  }

  &:hover {
    * {
      color: #fff !important;
      transition: 0.3s ease;
    }
  }
}

.ad-image {
  max-height: 30vh !important;
}

//.language {
//  max-height: 60vh;
//  overflow-y: scroll;
//}

@media (max-width: 1160px) {
  .home-title {
    font-size: 40px;
    text-align: center;
  }

  .home-logo {
    height: 50px;
  }

  .menu-item {
    height: 35vh;
  }
}
</style>
