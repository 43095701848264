import Vue from "vue";
import Vuex from "vuex";
import localforage from "localforage";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    langs: [],
    ads: [],
    langsAPI: [],
    languageHr: {
      title: 'Hrvatski',
      flag: 'hr',
      abbreviation: 'hr'
    },
    language: "hr",
    favs: {},
    favourites: [],
    favIds: [],
    cart: [],
    showAds: true,
    client: null,
    filters: null,
    regions: null,
    countries: null,
    productTypes: [],
    grouped_products: [],
  },
  getters: {
    favs: state => {
      let favs = [];
      let groups = state.grouped_products;
      Object.keys(groups).forEach((item) => {
        groups[item].forEach(product => {
          if (state.favIds.includes(product.id))
            favs.push(product)
        })
      });
      return favs;
    },
    getFavs: state => {
      let favs = [];
      let groups = state.grouped_products;
      Object.keys(groups).forEach((item) => {
        groups[item].forEach(product => {
          if (Object.keys(state.favs).filter(x=>x==product.id).length>0)
            favs.push({item: product, ...state.favs[product.id]})
        })
      });

      return favs;
    },
    favIds: state => {
      return state.favIds;
    },
    getCart: state => {
      return state.cart;
    },
    language: state => {
      return state.language;
    },
    getsupportedLangs: state => {
      // if there is slovenian language, change its flag to si
      let langs = state.langs;
      if (langs != null) {
        langs.forEach((lang) => {
          if (lang.abbreviation === "sl") {
            lang.flag = "si";
          }
        });
      }
      return langs;
    },
    getHomeAds: state => {
      return state.ads;
    },
    getLang: state => {
      if (state.langs != null)
        return state.langs.find(el => el.abbreviation === state.language);
      else return null;
    },
    showAds: state => {
      return state.showAds;
    },
    getClient: state => {
      return state.client;
    },
    getGroupedProducts: state => {
      // check if key with value of "10" exists in state.grouped_products, if it exists delete it
        if (state.grouped_products != null) {
            Object.keys(state.grouped_products).forEach((key) => {
            if (key === "10") {
                delete state.grouped_products[key];
            }
            });
        }
      return state.grouped_products;
    },
    getAds: state => {
      if (state.client) return state.client.ads;
      else return [];
    },
    /*getCocktails: state => {
            if (state.client)
                return shuffle(state.client.cocktails);
            else
                return [];
        },*/
    getProductsById: state => id => {
      if (state.client)
        if(id == 2 || id == 4)
          return state.grouped_products[id]
        else
          return shuffle(state.grouped_products[id]);
      else return [];
    },
    getProductsByClientProductIds: state => ids => {
      return Object.values(state.grouped_products)
        .flat(1)
        .filter((product) => ids.includes(product.client_product_id));
    },
    getProductsByName: state => id => {
      if (state.client) {
        return sortByColorPromotionName(state.grouped_products?.[id], state.client);
      } else return [];
    },
    getFilters: state => {
      return state.filters;
    },
    getFilterCatById: state => id => {
      return state.filters?.find(category => {
        return category.id == id;
      });
    },
    getFilterById: state => (cat, id) => {
      return state.filters
          ?.find(category => {
            return category.id == cat;
          })
          .filters.find(filter => filter.id == id);
    },
    getCountryById: state => id => {
      return state.countries?.find(country => {
        return country.id == id;
      });
    },
    getCountries: state => {
      return state.countries;
    },
    getRegions: state => {
      return state.regions;
    },
    getProductTypes: state => {
      return state.productTypes;
    },
    getTypeById: state => id => {
      return state.productTypes.find(type => {
        return type.id == id;
      });
    }
  },
  mutations: {
    setsupportedLangs: (state, langs) => {
      state.langs = langs;
    },
    setAds: (state, ads) => {
      state.ads = ads;
    },
    setLang: (state, lang) => {
      state.language = lang;
    },
    clearFavs: state => {
      state.favs = {};
      localforage.setItem('favorites', {})
      state.favIds = [];
      state.favourites = [];
    },
    clearCarts: state => {
      state.cart = [];
    },
    setProductTypes: (state, productTypes) => {
      state.productTypes = productTypes;
    },
    setClient: (state, client) => {
      state.client = client;
    },
    setGroupedProducts: (state, grouped_products) => {
      state.grouped_products = grouped_products;
    },
    setFilters: (state, filters) => {
      state.filters = filters;
    },
    setCountries: (state, countries) => {
      state.countries = countries;
    },
    setRegions: (state, regions) => {
      state.regions = regions;
    },
    setShowAds: (state, show) => {
      state.showAds = show;
    },
    setFavs: (state, favs) => {
      state.favs = favs
    },
  },
  actions: {
    async syncClientStatistics({commit, state}, payload){
      if (payload.fromServer) {
        let items = await localforage.getItem('client_statistics');
        if (items != undefined && items != null && !(JSON.stringify(items) === JSON.stringify({}))){
          vinaApi.post('client_statistics/bulk', {items: items, trackable_type: 'ClientProduct', app_name: 'tablet'}, {
            baseURL: 'https://report-api.master-fb.com/'
          })
              .then((response) => {
                localforage.setItem('client_statistics', {});
              })
              .catch(() => {
              })
        }
      }
    },
    async increaseClicks({ commit, state },payload) {
      // find id of product in localForage
      // if id exists update click count
      // if id does not exist create new key value pair and increase click count
      let items = await localforage.getItem('client_statistics');
      if (items==null || items==undefined){
        items = {};
      }
      if(items[payload.client_product_id]){
        items[payload.client_product_id].clicks++;
      }else{
        items[payload.client_product_id] = {
          clicks: 1,
          time_spent: 1
        };
      }
      await localforage.setItem('client_statistics', items);
    },
    async removeFromFav({commit, state}, payload){
      let items = await localforage.getItem('favorites');
      if (items==null || items==undefined){
        items = {};
      }
      if (items[payload.product_id]){
        delete items[payload.product_id]
      }
      await localforage.setItem('favorites', items);
      commit("setFavs", items);
    },
    async addToFav({commit, state}, payload){
      let items = await localforage.getItem('favorites');
      if (items==null || items==undefined){
        items = {};
      }
      items[payload.product_id] = {
        amount: 1,
        dose: null
      };
      await localforage.setItem('favorites', items);
      commit("setFavs", items);
    },
    async setFavoriteProductById({commit, state}, payload){
      let items = await localforage.getItem('favorites');
      if (items==null || items==undefined){
        items = {};
      }
      if(items[payload.product_id]){
        items[payload.product_id].amount = payload.amount;
        items[payload.product_id].dose = payload.dose;
      }
      await localforage.setItem('favorites', items);
      commit("setFavs", items);
    },
    async increaseTimeSpent({ commit, state },payload) {
      // find id of product in localForage
      // if id exists update time spent
      // if id does not exist create new key value pair and increase time spent
      let items = await localforage.getItem('client_statistics');
      if (items==null || items==undefined){
        items = {};
      }
      if(items[payload.client_product_id]){
        if(items[payload.client_product_id].time_spent || !isNaN(items[payload.client_product_id].time_spent)){
          items[payload.client_product_id].time_spent+=payload.time_spent;
        }else{
          items[payload.client_product_id].time_spent=payload.time_spent;
        }
      }else{
        items[payload.client_product_id] = {
          time_spent: payload.time_spent,
          clicks: 1
        };
      }
      await localforage.setItem('client_statistics', items);
    },
    async getsupportedLangs({ commit }, payload) {
      if (payload.fromServer) {
        vinaApi
            .get("/clients/hash/" + payload.hash + '/languages')
            .then(response => {
              if (response.status === 200) {
                localforage.setItem(
                    "client_langs",
                    response.data
                );
                commit("setsupportedLangs", response.data);
                let activeLang = response.data.filter(lang=>lang.active)[0] ?? response.data[0];
                commit("setLang", activeLang.abbreviation)
              }
            });
      }

      let langs = await localforage.getItem("client_langs");
      let activeLang =  localStorage.getItem("vinaLang");
      commit(
          "setsupportedLangs",
          langs
      );
      if(langs!=null && activeLang===null){
        let activeLang = langs?.filter(lang=>lang.active)[0] ?? langs[0];
        localStorage.setItem("vinaLang", activeLang.abbreviation);
        commit("setLang", activeLang.abbreviation)
      }
    },
    async getAds({ commit }, payload) {
      if (payload.fromServer) {
        vinaApi
            .get("/clients/hash/" + payload.hash + '/ads')
            .then(response => {
              if (response.status === 200) {
                localforage.setItem(
                    "client_ads",
                    response.data
                );
                commit("setAds", response.data);
              }
            });
      }
      commit(
          "setAds",
          await localforage.getItem("client_ads")
      );
    },
    async getClient({ commit, state }, payload) {
      if (payload.fromServer) {
        if (this.getters.getsupportedLangs != null)
        {
          this.getters.getsupportedLangs.forEach(lang => {
            vinaApi
                .get("/clients/hash/" + payload.hash, {
                  // params: {
                  //     'with': [
                  //         'ads', 'country', 'menuCountries'
                  //     ]
                  // },
                  headers: {
                    "X-Localization": lang.abbreviation
                  }
                })
                .then(response => {
                  if (response.status === 200) {
                    let data = response.data;
                    const grouped_products = data.grouped_products
                    vinaApi.get("/clients/"+data.id+"/availableFilters", {                headers: {
                        "X-Localization": lang.abbreviation
                      }}).then(response=>{
                          data.available_filters = response.data;
                          if (lang.abbreviation == state.language) {
                            commit("setClient", data);
                            commit("setGroupedProducts", grouped_products)
                          }
                          localforage.setItem(
                              "grouped_products_" + lang.abbreviation,
                              grouped_products
                          );
                          delete data.grouped_products;
                          localforage.setItem(
                              "client_" + lang.abbreviation,
                              data
                          );
                        }
                    )
                    /*let product_keys = []
                    for (const [key, value] of Object.entries(grouped_products)) {
                      let items = sliceIntoChunks(value, 50)
                      items.forEach((chunk, i) => {
                        try {
                          localforage.setItem(
                              "grouped_products_" + lang.abbreviation + i + '_' + key,
                              JSON.stringify(chunk)
                          );
                        } catch (e) {
                        }
                      })
                      product_keys.push(key)
                    }
                    localforage.setItem(
                        "product_keys",
                        JSON.stringify(product_keys)
                    );*/

                  }
                });
          });
        }
      }
      commit(
          "setClient",
          await localforage.getItem("client_" + state.language)
      );
    },
    async getFilters({ commit, state }, payload) {
      if (payload.fromServer) {
        if (this.getters.getsupportedLangs != null) {
          this.getters.getsupportedLangs.forEach(lang => {
            vinaApi
                .get("/filter_categories", {
                  params: {
                    with: ["filters"]
                  },
                  headers: {
                    "X-Localization": lang.abbreviation
                  }
                })
                .then(response => {
                  if (response.status === 200) {
                    localforage.setItem(
                        "filters_" + lang.abbreviation,
                        response.data
                    );
                    if (lang.abbreviation == state.language) {
                      commit("setFilters", response.data);
                    }
                  }
                });
          });
        }
      }
      commit(
          "setFilters",
          await localforage.getItem("filters_" + state.language)
      );
    },
    async getCountries({ commit, state }, payload) {
      if (payload.fromServer) {
        if (this.getters.getsupportedLangs != null) {
          this.getters.getsupportedLangs.forEach(lang => {
            vinaApi
                .get("/countries", {
                  headers: {
                    "X-Localization": lang.abbreviation
                  }
                })
                .then(response => {
                  if (response.status === 200) {
                    localforage.setItem(
                        "countries_" + lang.abbreviation,
                        response.data
                    );
                    if (lang.abbreviation == state.language) {
                      commit("setCountries", response.data);
                    }
                  }
                });
          });
        }

      }
      commit(
          "setCountries",
          await localforage.getItem("countries_" + state.language)
      );
    },
    async getRegions({ commit, state }, payload) {
      if (payload.fromServer) {
        if (this.getters.getsupportedLangs != null) {
          this.getters.getsupportedLangs.forEach(lang => {
            vinaApi
                .get("/regions", {
                  headers: {
                    "X-Localization": lang.abbreviation
                  }
                })
                .then(response => {
                  if (response.status === 200) {
                    localforage.setItem(
                        "regions_" + lang.abbreviation,
                        response.data
                    );
                    if (lang.abbreviation == state.language) {
                      commit("setRegions", response.data);
                    }
                  }
                });
          });
        }
      }
      commit(
          "setRegions",
          await localforage.getItem("regions_" + state.language)
      );
    },
    async getProductTypes({ commit, state }, payload) {
      if (payload.fromServer) {
        if (this.getters.getsupportedLangs != null) {
          this.getters.getsupportedLangs.forEach(lang => {
            vinaApi
                .get("/product_types", {
                  headers: {
                    "X-Localization": lang.abbreviation
                  }
                })
                .then(response => {
                  if (response.status === 200) {
                    localforage.setItem(
                        "product_types_" + lang.abbreviation,
                        response.data
                    );
                    if (lang.abbreviation == state.language) {
                      commit("setProductTypes", response.data);
                    }
                  }
                });
          });
        }
      }
      commit(
          "setProductTypes",
          await localforage.getItem("product_types_" + state.language)
      );
    },
    showAds({ commit }, show) {
      commit("setShowAds", show);
    },
    async setLang({ commit }, lang) {
      localStorage.setItem("vinaLang", lang);
      commit("setLang", lang);
      commit("setClient", await localforage.getItem("client_" + lang));
      /*let grouped = {
      };
      let i = 0;
      let keys = await localforage.getItem("product_keys")
      keys.forEach(key => {
        grouped[key] = []
        while (i < 3) {
          let chunk_data = await localforage.getItem("grouped_products_" + lang + i + '_' + key)
          grouped[key].push(...chunk_data)
          i++
        }
      });*/

      commit("setGroupedProducts", await localforage.getItem("grouped_products_" + lang));
      commit(
          "setProductTypes",
          await localforage.getItem("product_types_" + lang)
      );
      commit(
          "setCountries",
          await localforage.getItem("countries_" + lang)
      );
      commit("setRegions", await localforage.getItem("regions_" + lang));
      commit("setFilters", await localforage.getItem("filters_" + lang));
    }
  }
});

/*function sliceIntoChunks(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}*/

function shuffle(a) {
  function compare(x, y) {
    if (x.title < y.title) return -1;
    if (x.title > y.title) return 1;

    return 0;
  }
  var items = [];

  items = [...a].sort(compare);

  // return items
  // for (let i = a.length - 1; i > 0; i--) {
  //   const j = Math.floor(Math.random() * (i + 1));
  //   [a[i], a[j]] = [a[j], a[i]];
  // }
  return items.sort((x, y) => {
    return x.promotion > y.promotion ? -1 : 1;
  });
}

function sortByColorPromotionName(val, client) {
  function compare(a, b) {
    if (a.promotion > b.promotion) return -1;
    if (a.promotion < b.promotion) return 1;
    if (a.title < b.title) return -1;
    if (a.title > b.title) return 1;

    return 0;
  }

  function promotion_sort(a, b) {
    if (a.promotion > b.promotion) return -1;
    if (a.promotion < b.promotion) return 1;
  }

  var white = [];
  var black = [];
  var rose = [];
  var champagne = [];
  var rest = [];
  var no_filters = [];
  var item1 = [];
  var item2 = [];
  var item3 = [];
  var item4 = [];
  var item5 = [];
  var filters = undefined
  val.forEach(element => {
    if (element.grouped_filters !== null) {
      if (element.grouped_filters["2"]) {
        let el = element.grouped_filters["2"][0];
        if (client.client_filters.length !== 0) {
          filters = client.client_filters[2]
          if (filters !== undefined) {
            for (let j = 0; j < filters.length; j++) {
              var el2 = filters[j]
              if (el == el2.id && el2.sequence == 1) {
                item1.push(element);
                break
              } else if (el == el2.id && el2.sequence == 2) {
                item2.push(element);
                break
              } else if (el == el2.id && el2.sequence == 3) {
                item3.push(element);
                break
              } else if (el == el2.id && el2.sequence == 4) {
                item4.push(element);
                break
              } else {
                item5.push(element)
                break
              }
            }
          }
        }
        if (el == 7) {
          white.push(element);
        } else if (el == 2) {
          black.push(element);
        } else if (el == 8) {
          rose.push(element);
        } else if (el == 52) {
          champagne.push(element);
        } else {
          rest.push(element)
        }
      }
    } else {
      no_filters.push(element)

    }
  });



  var items = [];
  var items_white = [];
  var items_black = [];
  var items_rose = [];
  var items_champagne = [];
  var items_rest = [];
  var items_no_filters = [];

  var items_sorted = []
  var items_1 = [];
  var items_2 = [];
  var items_3 = [];
  var items_4 = [];
  var items_5 = [];

  items_1 = [...item1].sort(compare);
  items_2 = [...item2].sort(compare);
  items_3 = [...item3].sort(compare);
  items_4 = [...item4].sort(compare);
  items_5 = [...item5].sort(compare);

  items_white = [...white].sort(compare);
  items_black = [...black].sort(compare);
  items_rose = [...rose].sort(compare);
  items_champagne = [...champagne].sort(compare);
  items_rest = [...rest].sort(compare);
  items_no_filters = [...no_filters].sort(compare);

  items_sorted = [
    ...items_1,
    ...items_2,
    ...items_3,
    ...items_4,
    ...items_5
  ];

  var finished_sorted = []
  finished_sorted = [...items_sorted].sort(promotion_sort)

  items = [
    ...items_white,
    ...items_black,
    ...items_rose,
    ...items_champagne,
    ...items_rest,
    ...items_no_filters
  ];

  var finished = []
  finished = [...items].sort(promotion_sort)

  return filters !== undefined ? finished_sorted : finished;
}
